import axios from "../instance";

export default {
    getAll: (branchId) => {
        if (branchId) {
            return axios.api.get(`/vans?branchId=${branchId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/vans?companyId=${
                localStorage.getItem('companyId')
            }`).then((response) => response.data);
        }
    },
    getAvalibleVans: (warehouseId, branchId) => {
        return axios.api.get(`/warehouses/getAvalibleVans?warehouseId=${warehouseId}&branchId=${branchId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/vans/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/vans/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/vans", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/vans/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/vans/${id}`).then((response) => response.data);
    },
    getVanPlanning: (id) => {
        return axios.api.get(`/vanPlannings/findByVanId/${id}`).then((response) => response.data);
    },
    createPlanning: (body) => {
        return axios.api.post("/vanPlannings", body).then((response) => response.data);
    },
    updatePlanning: (id, body) => {
        return axios.api.put(`/vanPlannings/${id}`, body).then((response) => response.data);
    },
    getPlanningByDate: (vanId, planning_date) => {
        return axios.api.get(`/vanPlannings?vanId=${vanId}&planning_date=${planning_date}`).then((response) => response.data);
    },
    getTargetAmt: (targetMonth, companyId, vanId) => {
        return axios.api.get(`/vanTargets?targetMonth=${targetMonth}&companyId=${companyId}&vanId=${vanId}`).then((response) => response.data);
    },
    createTargetAmt: (body) => {
        return axios.api.post("/vanTargets", body).then((response) => response.data);
    },
    getRunning: (requestType) => {
        return axios.api.get(`/runningNumbers?requestType=${requestType}`).then((response) => response.data);
    },
    searchVanStock: (body) => {
        return axios.api.post("/vanStocks/search", body).then((response) => response.data);
    },
    manualGetVanStock: (vanCode) => {
        return axios.api.get(`/vanStocks/manualGetVanStock?vanCode=${vanCode}`).then((response) => response.data);
    },
    importPlanning: (body) => {
        return axios.api.post("/vanPlannings/importList", body).then((response) => response.data);
    },


};
