<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("addproduct") }}
      </h3>
      <div class="card-toolbar">
        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="ข้อมูลสินค้า.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel"
          @mouseenter="hoverABtn"
        >
          {{ $t("importdatafromexcel") }}
        </button> -->
        <!-- <div v-if="hoverA">
          <DialogExportExcel
            :dialogImportExcel="dialogImportExcel"
            @closeDialogImportExcel="closeDialogImportExcel"
          />
        </div> -->
        <button
          v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-4">
        <label form-label>Code/Name</label>
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
          @keypress.enter="search()"
        />
      </div>
      <!-- <select v-model="searchBy" class="form-select">
        <option value="1">ตามรหัสสินค้า</option>
        <option value="2">ตาม Barcode</option>
        <option value="3">ตาม POG</option>
      </select> -->
      <div class="col-sm-3 mb-3">
        <label form-label>Item Group</label>
        <!-- v-model="productGroupHeaderCodeHeaderId" -->
        <select
          v-model="itemGroupHeaderId"
          class="form-select mb-4"
          id="validationTooltip01"
          required
          show-search
          option-label-prop="label"
        >
          <option v-for="item in groups" :key="item" :value="item.id">
            {{ item.code }} |
            {{ item.name }}
          </option>
        </select>
      </div>
      <!-- <div class="col-sm-3 mb-3">
        <select
          class="form-select mb-4"
          v-model="typeId"
          id="validationTooltip01"
          required
        >
          <option v-for="item in types" :key="item" :value="item.id">
            {{ item.sub_product_group_code }} |
            {{ item.sub_product_group_name }}
          </option>
        </select>
      </div> -->

      <div class="col-sm-3" style="align-self: center">
        <label form-label></label>
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'code'">
              {{ $t("productcode") }}
            </template>
            <template v-if="column.dataIndex === 'productName'">
              {{ $t("productname") }}
            </template>
            <template v-if="column.dataIndex === 'productGroupHeaderCode'">
              {{ $t("productgroupname") }}
            </template>
            <template v-if="column.dataIndex === 'productUnitName'">
              {{ $t("unitname") }}
            </template>
            <template v-if="column.dataIndex === 'productPrices.cost'">
              {{ $t("cost") }}
            </template>
            <template v-if="column.dataIndex === 'unit_price'">
              {{ $t("sellprice") }}
            </template>
            <template v-if="column.dataIndex === 'status'">
              {{ $t("status") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <!-- <template v-if="column.dataIndex === 'productGroupHeaderCode'">
              <span v-if="record.itemGroupHeaderId">
                {{ record.itemGroupHeader ? record.itemGroupHeader.code : "" }}
                {{
                  record.itemGroupHeader
                    ? " | " + record.itemGroupHeader.name
                    : ""
                }}
              </span>
            </template> -->
            <!-- <template v-if="column.dataIndex === 'productUnitName'">
              <span v-if="record.productPrices.length">
                {{
                  record.productPrices[0].productUnit
                    ? record.productPrices[0].productUnit.name
                    : "-"
                }}
              </span>
              <span v-else>-</span>
            </template> -->
            <template v-if="column.dataIndex === 'productPrices.cost'">
              <span v-if="record.productPrices">
                {{
                  record.productPrices.length
                    ? record.productPrices[0].cost
                    : "-"
                }}
              </span>
              <span v-else>-</span>
            </template>

            <template v-if="column.dataIndex === 'unit_price'">
              <span v-if="record.productPrices">
                {{
                  record.productPrices.length
                    ? record.productPrices[0].unit_price
                    : "-"
                }}
              </span>
              <span v-else>-</span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 'ขายปกติ'" style="color: green">{{
                record.status
              }}</span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div
                  class="col-sm-6 d-flex justify-content-md-end"
                  v-if="userAuth.isCanModified"
                >
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>
        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("productcode") }}</th>
              <th>{{ $t("productname") }}</th>
              <th>{{ $t("productgroupname") }}</th>
              <th>{{ $t("cost") }}</th>
              <th>{{ $t("status") }}</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="15">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td v-if="item.productGroupHeaderCode">
                {{ item.productGroupHeaderCode.code }} {{ item.productGroupHeaderCode.name }}
              </td>
              <td v-if="item.productPrices.length != 0">
                {{ item.productPrices[0].cost }}
              </td>
              <td v-else>-</td>
              <td v-if="item.status == 'ขายปกติ'" style="color: green">
                {{ item.status }}
              </td>
              <td v-else style="color: red">{{ item.status }}</td>

              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      @mouseenter="hoverBBtn"
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <div v-if="hoverB">
      <DialogConfirmByPass
        :dialogConfirmByPass="dialogConfirmByPass"
        :loadingUpdate="loadingUpdate"
        @submitConfirmPass="submitConfirmPass"
        @closeDialogConformByPass="closeDialogConformByPass"
      />
    </div>

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";

import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";
import DialogExportExcel from "./components/dialogImportExcel.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";
import { Decode } from "@/services";

export default {
  components: {
    DialogExportExcel,
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "J&N | WareHouse Library";
  },
  data: () => ({
    json_fields: {
      code: "code",
      productName: "name",
      productGroupHeaderCode: "productGroupHeaderCode",
      itemGroupHeader: {
        field: "itemGroupHeader",
        callback: (value) => {
          return ` ${value.name ? value.name : ""}`;
        },
      },

      productPrices: {
        field: "productPrices",
        callback: (value) => {
          return ` ${value[0].productUnit.name}`;
        },
      },
      status: "status",
    },
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      { title: "productName", dataIndex: "productName" },
      { title: "productGroupHeaderCode", dataIndex: "productGroupHeaderCode" },
      { title: "productUnitName", dataIndex: "productUnitName" },
      // { title: "cost", dataIndex: "productPrices.cost" },
      // { title: "unit_price", dataIndex: "unit_price" },
      // { title: "status", dataIndex: "status" },
      { title: "", dataIndex: "action" },
    ],
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,

    dialogConfirmByPass: false,
    dataItemsShow: [],
    showing1: null,

    hoverA: false,
    hoverB: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchBy: "1",
    allData: [],
    groups: [],
    types: [],
    itemGroupHeaderId: null,
    typeId: 0,

    userAuth: [],
    userLocal: [],
    masterMenuId: 6,
  }),

  async created() {
    this.checkMenu();
    await this.getAllGroup();
    await this.getAll();
  },

  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.searchPricelist({
          companyId: localStorage.getItem("companyId"),
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        // this.filterItems(this.dataItemsShow);
        this.loading = false;
        // if (method === 1) {
        //   await this.$router.push({
        //     query: {
        //       page: this.$route.query.page,
        //       get: 1,
        //     },
        //   });
        // }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },
    async getAllGroup() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.itemGroup.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.groups = responseData.data;
        this.loading = false;
        this.groups.unshift({ name: "ทั้งหมด", id: "" });
      }
    },

    hoverABtn() {
      this.hoverA = true;
      this.hoverB = false;
    },
    hoverBBtn() {
      this.hoverB = true;
      this.hoverA = false;
    },

    //--- Pagination --- start
    // filterItems(val) {
    //   console.log("val length", val.length);
    //   this.dataItemsShow = val;
    // },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/warehouse/WH4/new",
        query: {},
      });
    },

    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "WH4-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "WH4-edit",
      //   query: {
      //     id: id,
      //   },
      // });
    },
    gotoImportExcel() {
      // this.$router.push("/warehouse/WH4/importexcel");
      // document.getElementById("kt_modal_1").click();
      this.dialogImportExcel = true;
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.name} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // this.confirmDelete(item, index);

          this.handleApprove(item);
        }
      });
    },
    async confirmDelete() {
      let deleteResponse = [];
      const findIndex = this.dataItemsShow.indexOf(this.itemApprove);

      deleteResponse = await whApi.product.delete(this.itemApprove.id);

      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          tititle: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(findIndex, 1);
          this.tableItems.splice(findIndex, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      if (isApprove) {
        this.confirmDelete();
      }
      // let updateResponse = [];
      // const id = this.itemApprove.id;

      // this.itemApprove = {
      //   ...this.itemApprove,
      //   status: isApprove ? 1 : 2,
      // };
      // updateResponse = await whApi.product.update(id, this.itemApprove);
      // if (updateResponse.response_status === "SUCCESS") {
      //   if (isApprove) {
      //     Swal.fire({
      //       icon: "success",
      //       title: `อนุมัติสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   } else {
      //     Swal.fire({
      //       icon: "error",
      //       title: `ไม่อนุมัติ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      //   this.loadingUpdate = false;
      //   this.getAll(1);
      // } else {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "อนุมัติไม่สำเร็จ !",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   this.loadingUpdate = false;
      // }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    closeDialogImportExcel() {
      this.dialogImportExcel = false;
    },
    textSearch(val) {
      console.log({ val });
    },
    async search() {
      this.loading = true;
      // let responseSearch = [];
      let body = {
        search: this.searchInput,
        itemGroupHeaderId: this.itemGroupHeaderId,
        companyId: localStorage.getItem("companyId"),
      };
      // console.log("body", body);
      const responseSearch = await whApi.product.searchPricelist(
        body
        // this.itemGroupHeaderId
      );

      if (responseSearch.response_status === "SUCCESS") {
        console.log("responseSearch", responseSearch);
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
